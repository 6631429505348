<div id="upload-dialog-choose">
  <h2 id="headline" mat-dialog-title>
    {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD.TITLE' | translate }}
    <button mat-dialog-close>
      <mat-icon svgIcon="close-button"></mat-icon>
    </button>
  </h2>
  <mat-dialog-content class="content">
    <button class="left" (click)="clickSelectUpload()">
      <mat-icon svgIcon="upload-icon"></mat-icon>
      <span class="icon-text">
        {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD.NAV-UPLOAD' | translate }}
      </span>
    </button>
    <button disabled class="right" (click)="clickSelectDB()">
      <mat-icon svgIcon="imagedb-icon"></mat-icon>
      <span class="icon-text">
        {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD.NAV-IMG-DB' | translate }}
      </span>
    </button>
  </mat-dialog-content>
</div>
