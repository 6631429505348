<div id="upload-dialog-sortimo-image">
  <h2 id="headline" mat-dialog-title>
    {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD-IMAGE-DB.TITLE' | translate }}
    <button mat-dialog-close>
      <mat-icon svgIcon="close-button"></mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    <div id="spinnerContainer" *ngIf="fileUploadActive">
      <mat-spinner class="colored-spinner"></mat-spinner>
    </div>
    <div id="filter-container">
      <select
        id="category-select"
        class="mat-select-arrow-wrapper"
        size="1"
        (change)="changeCategory($event)"
      >
        <option selected value="">
          {{
            'EDITOR.UI.POPUP.IMAGE-UPLOAD-IMAGE-DB.SELECT-PLACEHOLDER'
              | translate
          }}</option
        >
        <option *ngFor="let category of allCategory">{{ category }}</option>
      </select>
      <label id="filter-input-label">
        <input
          id="filter-input"
          type="text"
          placeholder="{{
            'EDITOR.UI.POPUP.IMAGE-UPLOAD-IMAGE-DB.SEARCH-PLACEHOLDER'
              | translate
          }}"
          [(ngModel)]="searchFilterContent"
          (keyup)="searchKeyUp()"
        />
        <mat-icon svgIcon="search-icon"></mat-icon>
      </label>
    </div>
    <div id="image-list-content">
      <span *ngFor="let image of allImageCurrentPage">
        <app-sortimo-image-display
          *ngIf="image"
          [image]="image"
          [selected]="isSelected(image)"
          (click)="selectImage(image)"
        ></app-sortimo-image-display>
      </span>
    </div>
    <div id="page-control-container">
      <button (click)="jumpToStart()" [disabled]="!previousPageEnabled">
        <mat-icon
          style="transform: rotate(180deg);"
          svgIcon="ans-ende-icon"
        ></mat-icon>
      </button>
      <button (click)="previousPage()" [disabled]="!previousPageEnabled">
        <mat-icon
          style="transform: rotate(180deg);"
          svgIcon="weiter-icon"
        ></mat-icon>
      </button>
      <span id="page-label">
        {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD-IMAGE-DB.PAGE-FRONT' | translate }}
        {{ currentPage }}
        {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD-IMAGE-DB.PAGE-MID' | translate }}
        {{ numberOfPages }}
      </span>
      <button (click)="nextPage()" [disabled]="!nextPageEnabled">
        <mat-icon svgIcon="weiter-icon"></mat-icon>
      </button>
      <button (click)="jumpToEnd()" [disabled]="!nextPageEnabled">
        <mat-icon svgIcon="ans-ende-icon"></mat-icon>
      </button>
    </div>
    <div id="button-container">
      <button class="btn btn-outline-primary sortimo_button" mat-dialog-close>
        {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD-IMAGE-DB.BUTTON-CANCEL' | translate }}
      </button>
      <button
        class="btn btn-primary sortimo_button"
        [disabled]="!selectedImage || fileUploadActive"
        (click)="addImageFromDB()"
      >
        {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD-IMAGE-DB.BUTTON-PLACE' | translate }}
      </button>
    </div>
  </mat-dialog-content>
</div>
