<div id="editor-alert-message" [style.background-color]="color" class="flex-row">
  <mat-icon *ngIf="isWarning" svgIcon="icon-warning-message"></mat-icon>
  <mat-icon *ngIf="isError" svgIcon="icon-error-message"></mat-icon>
  <div class="text-container flex-col" [style.color]="textColor">
    <span class="headline"> {{ headline }} </span>
    <span class="message">
      {{ textContent }}
      <a [href]="linkTarget" target="_blank">{{ linkText }}</a></span
    >
  </div>
  <button class="close-button" (click)="closeClick()">
    <mat-icon
      [style.stroke]="iconColor"
      [style.fill]="iconColor"
      svgIcon="icon-close"
    ></mat-icon>
  </button>
</div>
