<div class="flex-col flex-1" id="preview-container">
  <div
    [hidden]="!showInitialLoadingCircle"
    class="svgcontainer-content-overlap"
  >
    <mat-spinner
      class="colored-spinner"
      style="margin: 120px auto"
    ></mat-spinner>
  </div>
  <div class="preview-stage">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1280 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      style="
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linejoin: round;
        stroke-miterlimit: 1.41421;
      "
    >
      <path id="Rectangle.-6.-Copy" d="M84,0l1112,0l84,24l-1280,0l84,-24Z" />
    </svg>
  </div>

  <div class="preview-content flex-col">
    <div class="canvas-svg-content flex-col">
      <div class="flex-1"></div>
      <div class="directContainer">
        <div
          [hidden]="showInitialLoadingCircle || !showLoadingCircle"
          class="svgcontainer-content-overlap"
        >
          <mat-spinner
            class="colored-spinner"
            style="margin: 120px auto"
          ></mat-spinner>
        </div>

        <app-car-side-handler #carsvg></app-car-side-handler>
        <canvas #canvas></canvas>
      </div>
      <div *ngIf="showDivBehindCanvas()" class="flex-1"></div>
    </div>

    <div class="preview-step">
      <app-preview-step [step]="sideIndex + 1"></app-preview-step>
    </div>
    <div
      class="preview-button-bar flex-row space-between items-center"
    >
      <button
        *ngIf="!isInReadOnlyMode()"
        [disabled]="isFinishingOrder"
        class="btn btn-outline-primary sortimo_button background-white outer-button-left"
        (click)="onClickBackToDesign()"
      >
        {{ 'PREVIEW.GESTALTUNG' | translate }}
      </button>
      <div *ngIf="isInReadOnlyMode()" class="outer-button-left"></div>
      <app-aktueller-preis
        *ngIf="
          !showInitialLoadingCircle &&
          (!isInReadOnlyMode() || hasPredefinedPrice())
        "
      ></app-aktueller-preis>
      <div *ngIf="isInReadOnlyMode()" class="aktueller-preis-replacement"></div>
      <div class="flex-col">
        <h2 class="flex-1" *ngIf="!isInReadOnlyMode()">
          {{ 'PREVIEW.PRUEFEN' | translate }}
        </h2>
        <div class="flex-row">
          <button
            [disabled]="isFinishingOrder || disableReadOnlyClose"
            *ngIf="!isInInitialStateReadOnly()"
            class="btn btn-outline-primary sortimo_button margin-right-xl background-white left-button"
            (click)="onClickLeftButton()"
          >
            <mat-icon class="left" svgIcon="preview-next-icon"></mat-icon>

            <span class="right"> {{ 'PREVIEW.PREVIOUS' | translate }}</span>
          </button>

          <button
            [disabled]="isFinishingOrder || disableReadOnlyClose"
            class="btn btn-primary icon-button right-button margin-right-xl button-styling"
            (click)="onClickRightButton()"
          >
            <mat-icon
              *ngIf="showSaveIcon()"
              class="finishOrder"
              svgIcon="save"
            ></mat-icon>
            <span class="left"> {{ getRightButtonLabel() }}</span>
            <mat-icon
              *ngIf="!showSaveIcon()"
              class="right"
              svgIcon="preview-next-icon"
            ></mat-icon>
          </button>
          <button
            [disabled]="isFinishingOrder"
            *ngIf="showSecondOptionButton()"
            class="btn btn-primary icon-button right-button button-styling"
            style="max-width: 100%"
            (click)="onClickSecondOption()"
          >
            <mat-icon class="finishOrder" svgIcon="warenkorb"></mat-icon>
            <span> {{ getSecondOptionLabel() }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <a
    href="3rdpartylicenses.txt"
    target="_blank"
    class="font-10px"
    style="color: #8590a2; left: 3px; top: 725px; position: absolute"
    >Licenses</a
  >
</div>
