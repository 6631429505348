<div class="track-select flex-col">
  <div [hidden]="!showLoadingCycle" class="loading-cicle-area">
    <mat-spinner
      class="colored-spinner"
      style="margin: 120px auto;"
    ></mat-spinner>
  </div>
  <img class="banner" src="../assets/content/header-fast-track.jpg" />
  <h2 class="headline">{{ 'TRACK-SELECTION.HEADLINE' | translate }}</h2>
  <div class="flex-row">
    <div
      class="card-container card-left flex-col flex-50"
      [ngClass]="getClass(1)"
      (click)="!isFastTrackDisabled && selectFastTrack()"
    >
      <img class="content-image" [src]="imagePathFastTrack" />
      <img
        *ngIf="selectedOption === 1"
        class="cornor-position"
        src="../assets/content/icon_check.png"
      />
    </div>
    <div
      class="card-container card-right flex-col flex-50"
      [ngClass]="getClass(2)"
      (click)="selectEditor()"
    >
      <img class="content-image" [src]="imagePathEditor" />
      <img
        *ngIf="selectedOption === 2"
        class="cornor-position"
        src="../assets/content/icon_check.png"
      />
    </div>
  </div>
  <div
    class="fahrzeugwahl-button-bar flex-row"
  >
    <div class="flex-1"></div>
    <div></div>
    <button
      [disabled]="!hasSelection()"
      (click)="nextView()"
      class="btn btn-primary sortimo_button"
      angulartics2On="click"
      angularticsEvent="select"
      angularticsCategory="TrackSelection"
      [angularticsProperties]="{
        label:
          this.selectedOption === 1
            ? 'fast-track'
            : this.selectedOption === 2
            ? 'normal'
            : 'none'
      }"
    >
      <span class="left">{{ 'TRACK-SELECTION.NEXT' | translate }}</span>
      <mat-icon class="right" svgIcon="preview-next-icon"></mat-icon>
    </button>
  </div>

  <a
    href="3rdpartylicenses.txt"
    target="_blank"
    class="font-10px"
    style="color: #8590a2; left: 3px; top: 725px; position: absolute;"
    >Licenses</a
  >
</div>
