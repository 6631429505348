
<div
  class="car-color-selector flex-row"
  style=" display: flex; align-items: end;"
>
<p
matTooltip="{{ 'EDITOR.TOOLTIP.CHANGE-CAR-COLOR' | translate }}"
[style.opacity]="getOpacityForColor()"
>
{{ 'EDITOR.CONTROL.SHAPE-COLOR' | translate }}
</p>
<button
    (click)="fillClick()"
    matTooltip="{{ fillColorName }}"
    angulartics2On="click"
    angularticsEvent="CHANGE-COLOR-ELEMENT"
    angularticsCategory="EditorFunction"
  >
    <div class="color-div" [style.background]="fillColor"></div>
  </button>

</div>