<div id="upload-dialog-own-image">
  <h2 id="headline" mat-dialog-title>
    {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD-OWN-IMAGE.TITLE' | translate }}
    <button mat-dialog-close>
      <mat-icon svgIcon="close-button"></mat-icon>
    </button>
  </h2>
  <mat-dialog-content id="dialog-content">
    <div id="spinnerContainer" *ngIf="uploadActive">
      <mat-spinner class="colored-spinner"></mat-spinner>
    </div>
    <div id="rights-container">
      <app-toggle-switch
        [inputId]="'accepted-toggle-switch'"
        [labelWidth]="'0px'"
        [labelHeight]="'27px'"
        [isChecked]="isAccepted()"
        [isDisabled]="true"
        [isImageUploadParent]="true"
        (click)="toggleAccepted()"
      ></app-toggle-switch
      ><span>
        {{ 'EDITOR.MESSAGE.CONFIRM-IMAGE-RIGHTS-TEXT' | translate }}</span
      >
    </div>
    <div id="upload-drop-container" #dragContainer>
      <mat-icon svgIcon="upload-icon"></mat-icon>
      <span class="font-bold">
        {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD-OWN-IMAGE.TEXT1' | translate }}
      </span>
      <span>
        {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD-OWN-IMAGE.TEXT2' | translate }}
      </span>
      <button
        class="btn btn-primary sortimo_button"
        [disabled]="!isAccepted() || uploadActive"
        (click)="uploadImage()"
      >
        {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD-OWN-IMAGE.SELECT-FILE' | translate }}
      </button>

      <span>
        {{ uploadImagePopupText | translate }}</span
      >
      <input
        #imageInput
        type="file"
        [hidden]="true"
        (change)="onChangeFile($event)"
        [accept]="acceptFilesString"
      />
    </div>
    <div id="action-container">
      <button class="btn btn-outline-primary sortimo_button" mat-dialog-close>
        {{ 'EDITOR.UI.POPUP.IMAGE-UPLOAD-OWN-IMAGE.BUTTON' | translate }}
      </button>
    </div>
  </mat-dialog-content>
</div>
