<div
  class="editor-header flex-row gap-10 justify-center items-center"
>
  <div class="flex-1 ">{{ displayText }}</div>

  <button
    class="help-support-button flex-row justify-center items-center"
    angulartics2On="click"
    angularticsEvent="HELP-SUPPORT"
    angularticsCategory="HeaderFunction"
    (click)="openSupportPage()"
  >
    <mat-icon svgIcon="support-icon" class="supportIcon"></mat-icon>
    <p>{{ 'EDITOR.HEADER.HELP-SUPPORT' | translate }}</p>
  </button>

  <button
    [class]="vorlagenButtonClass"
    (click)="templateButtonClicked()"
    [disabled]="isTemplateNavButtonDisabled"
    angulartics2On="click"
    angularticsEvent="TEMPLATE-BAR"
    angularticsCategory="HeaderFunction"
  >
    {{ textForTemplateButton }}
    <mat-icon svgIcon="arrow-icon" class="arrowIcon"></mat-icon>
  </button>
</div>
