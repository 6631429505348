<div
  class="shape-color-selector flex-row items-end"
  fxLayoutAlignment="start center"
>
  <p
    matTooltip="{{ 'EDITOR.TOOLTIP.CHANGE-COLOR-ELEMENT' | translate }}"
    [style.opacity]="getOpacityForColor()"
  >
    {{ 'EDITOR.CONTROL.SHAPE-COLOR' | translate }}
  </p>
  <button
    (click)="fillClick()"
    [disabled]="isFillDisabled"
    matTooltip="{{ fillColorName }}"
    angulartics2On="click"
    angularticsEvent="CHANGE-COLOR-ELEMENT"
    angularticsCategory="EditorFunction"
  >
    <div class="color-div" [style.background]="fillColor"></div>
  </button>
  <button
    class=""
    (click)="strokeClick()"
    [disabled]="isStrokeWidthDisabled"
    matTooltip="{{ 'EDITOR.TOOLTIP.CHANGE-BORDER-COLOR' | translate }}"
  >
    <div
      *ngIf="showOutlineColor"
      class="border-div"
      [style.border-color]="borderColor"
    ></div>
    <mat-icon
      *ngIf="!showOutlineColor"
      [style.opacity]="getOpacityForStroke()"
      matTooltip="{{ 'EDITOR.TOOLTIP.CHANGE-BORDER-COLOR' | translate }}"
      svgIcon="no-border"
    ></mat-icon>
  </button>
  <p [style.opacity]="getOpacityForStrokeWidth()">
    {{ 'EDITOR.CONTROL.BORDER-COLOR' | translate }}
  </p>

  <input
    #widthInput
    [disabled]="isStrokeWidthDisabled"
    min="0"
    [(ngModel)]="strokeWidth"
    type="number"
  />
</div>
