<div class="flex-col flex-100" id="konfigurator">
  <!-- Use any element to open the sidenav -->
  <app-editor-header
    [isTemplateNavHidden]="isHiddenSidenav"
    [isTemplateNavButtonDisabled]="isTemplateNavButtonDisabled"
    (showAllTemplateClicked)="toogleTempNavOpen()"
    class="lightgrey flex-row"
  ></app-editor-header>
  <div #editorContainer class="editor font-16px flex-col flex-100">
    <app-editor
      (fullyInitializedEmitter)="onEditorFullyInitialized()"
    ></app-editor>

    <div
      *ngIf="!isInZoomMode()"
      class="car-side-label-container flex-col justify-flex-start"
      >
      <button
        class="car-side-button car-fahrer gap-5 justify-flex-start items-center flex-25"
        (click)="onChangeSide(1)"
        [disabled]="isDisabled(1)"
        angulartics2On="click"
        angularticsEvent="CAR-SIDE"
        angularticsCategory="EditorFunction"
        [angularticsProperties]="{ label: 'links' }"
      >
        <mat-icon svgIcon="image-car-fahrer">
          <mat-icon
            class="car-side-error-icon"
            [style.visibility]="carSideContainsError(1) ? 'visible' : 'hidden'"
            svgIcon="car-side-error-icon"
          ></mat-icon>
        </mat-icon>
        <p>{{ 'KONFIGURATOR.CAR-SIDE-SWITCHER.FAHRER' | translate }}</p>
      </button>
      <button
        class="car-side-button car-beifahrer gap-5 justify-flex-start items-center flex-25"
        (click)="onChangeSide(0)"
        [disabled]="isDisabled(0)"
        angulartics2On="click"
        angularticsEvent="CAR-SIDE"
        angularticsCategory="EditorFunction"
        [angularticsProperties]="{ label: 'rechts' }"
      >
        <mat-icon svgIcon="image-car-beifahrer">
          <mat-icon
            class="car-side-error-icon"
            [style.visibility]="carSideContainsError(0) ? 'visible' : 'hidden'"
            svgIcon="car-side-error-icon"
          ></mat-icon>
        </mat-icon>
        <p>{{ 'KONFIGURATOR.CAR-SIDE-SWITCHER.BEIFAHRER' | translate }}</p>
      </button>
      <button
        class="gap-5 justify-flex-start items-center flex-25"
        [class]="carFrontCssClasses"
        (click)="onChangeSide(2)"
        [disabled]="isDisabled(2)"
        angulartics2On="click"
        angularticsEvent="CAR-SIDE"
        angularticsCategory="EditorFunction"
        [angularticsProperties]="{ label: 'front' }"
      >
        <mat-icon svgIcon="image-car-front">
          <mat-icon
            class="car-side-error-icon"
            [style.visibility]="carSideContainsError(2) ? 'visible' : 'hidden'"
            svgIcon="car-side-error-icon"
          ></mat-icon>
        </mat-icon>
        <p>{{ 'KONFIGURATOR.CAR-SIDE-SWITCHER.FRONT' | translate }}</p>
      </button>
      <button
        class="gap-5 justify-flex-start items-center flex-25"
        [class]="carHeckCssClasses"
        (click)="onChangeSide(3)"
        [disabled]="isDisabled(3)"
        angulartics2On="click"
        angularticsEvent="CAR-SIDE"
        angularticsCategory="EditorFunction"
        [angularticsProperties]="{ label: 'heck' }"
      >
        <mat-icon svgIcon="image-car-heck">
          <mat-icon
            class="car-side-error-icon"
            [style.visibility]="carSideContainsError(3) ? 'visible' : 'hidden'"
            svgIcon="car-side-error-icon"
          ></mat-icon>
        </mat-icon>
        <p>{{ 'KONFIGURATOR.CAR-SIDE-SWITCHER.HECK' | translate }}</p>
      </button>
    </div>
  </div>
  <button
    class="mirror-button justify-flex-start items-center"
    *ngIf="!hideMirrorButton() && !isInZoomMode()"
    [disabled]="isMirrorButtonDisabled()"
    (click)="mirrorButtonClicked()"
    [matTooltip]="mirrorSideString"
    angulartics2On="click"
    angularticsEvent="Mirror"
    angularticsCategory="EditorFunction"
  >
    <mat-icon svgIcon="mirror-icon"></mat-icon>
    <p>{{ 'KONFIGURATOR.MIRROR-BUTTON' | translate }}</p>
  </button>

  <div
    #sidenav
    *ngIf="showTemplateNav() && !isInZoomMode()"
    class="template-navigation-container flex-col"
  >
    <div class="template-wnd-inner flex-col">
      <div
        *ngIf="getAllMinimalTemplate() && getAllMinimalTemplate().length > 0"
        class="template-type-container flex-col"
      >
        <div class="lightgrey template-headline">
          <span>{{ 'KONFIGURATOR.VORLAGEN.MINIMAL' | translate }}</span>
        </div>
        <div
          class="template-header template-container font-bold font-14px flex-col"
        >
          <div
            class="templateItem"
            *ngFor="let item of getAllMinimalTemplate()"
          >
            <a
              (click)="selectTemplate(item, true)"
              angulartics2On="click"
              angularticsEvent="SELECT-TEMPLATE-MINIMAL"
              angularticsCategory="EditorFunction"
              [angularticsProperties]="{ label: item.name }"
            >
              <img [src]="getPreviewImagePath(item)" alt="previewImage" />
            </a>
            <div
              *ngIf="isTemplateSelected(item)"
              class="templatecheckedoverlay"
            >
              <mat-icon
                svgIcon="template-checked"
                class="templatecheckedicon"
              ></mat-icon>
            </div>

            <div class="price">
              <span class="ab-label">{{
                'KONFIGURATOR.VORLAGEN.TEMPLATE-CIRCA' | translate
              }}</span>
              <br />
              <span>{{ getPriceForTemplate(item) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="template-type-container flex-col">
        <div class="lightgrey template-headline">
          <span>{{ 'KONFIGURATOR.VORLAGEN.BASIC' | translate }} </span>
        </div>
        <div
          class="template-header template-container font-bold font-14px flex-col"
        >
          <div class="templateItem" *ngFor="let item of getAllBasicTemplate()">
            <a
              (click)="selectTemplate(item, true)"
              angulartics2On="click"
              angularticsEvent="SELECT-TEMPLATE-BASIC"
              angularticsCategory="EditorFunction"
              [angularticsProperties]="{ label: item.name }"
            >
              <img [src]="getPreviewImagePath(item)" alt="previewImage" />
            </a>
            <div
              *ngIf="isTemplateSelected(item)"
              class="templatecheckedoverlay"
            >
              <mat-icon
                svgIcon="template-checked"
                class="templatecheckedicon"
              ></mat-icon>
            </div>

            <div class="price">
              <span class="ab-label">{{
                'KONFIGURATOR.VORLAGEN.TEMPLATE-CIRCA' | translate
              }}</span>
              <br />
              <span>{{ getPriceForTemplate(item) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-aktueller-preis
    [allowEdit]="true"
    class="current-price"
  ></app-aktueller-preis>

  <button
    class="btn btn-outline-primary sortimo_button margin-right-xl background-white backbutton"
    [disabled]="previousViewDisabled"
    (click)="previousView()"
  >
    <mat-icon class="left" svgIcon="preview-next-icon"></mat-icon>

    <span class="right">{{ 'KONFIGURATOR.BUTTON-BAR.BACK' | translate }}</span>
  </button>
  <button
    class="btn btn-primary sortimo_button vorschaubutton"
    [disabled]="anyCarSideContainsError()"
    (click)="forewardButtonClicked()"
  >
    <span class="left">{{
      'KONFIGURATOR.BUTTON-BAR.GESTALTUNG' | translate
    }}</span>
    <mat-icon class="right" svgIcon="preview-next-icon"></mat-icon>
    <mat-icon
      class="error-icon"
      [style.visibility]="anyCarSideContainsError() ? 'visible' : 'hidden'"
      svgIcon="car-side-error-icon"
    ></mat-icon>
  </button>

  <div class="overlay" #overlay>
    <div class="spinner-container">
      <mat-spinner class="colored-spinner" style="margin: 0 auto"></mat-spinner>
    </div>
  </div>
  <a
    href="3rdpartylicenses.txt"
    target="_blank"
    class="font-10px"
    style="color: #8590a2; left: 3px; top: 725px; position: absolute"
    >Licenses</a
  >
</div>
