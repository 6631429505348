<div id="measurement-component" class="flex-row">
  <div class="flex-row" *ngIf="!rotationOnly">
    <mat-icon svgIcon="icon-element-width"></mat-icon>
    <input #widthInput type="number" [min]="minElementSize" [(ngModel)]="currentWidth" />
    <p>{{ 'EDITOR.CONTROLS.UNIT' | translate }}</p>
  </div>
  <div class="flex-row" *ngIf="!rotationOnly">
    <mat-icon svgIcon="icon-element-height"></mat-icon>
    <input #heightInput type="number" [min]="minElementSize" [(ngModel)]="currentHeight" />
    <p>{{ 'EDITOR.CONTROLS.UNIT' | translate }}</p>
  </div>
  <div class="flex-row">
    <mat-icon svgIcon="icon-rotation"></mat-icon>
    <input #rotationInput type="number" min="0"  [disabled]="isRotationDisabled" [(ngModel)]="currentRotation"/>
    <p>{{ 'EDITOR.CONTROLS.DEGREE' | translate }}</p>
  </div>
</div>
