<p>
  {{ textKey | translate }}
</p>

<mat-toolbar class="icon-dialog-toolbar">
  <mat-toolbar-row>
    <div stype=" margin-left: -5px;">
      <input
        class="checkbox"
        style="width: 15px; height: 15px; margin-right: 10px;"
        type="checkbox"
        [(ngModel)]="checked"
      />
      {{ 'ICON-DIALOG.BESTAETIGEN' | translate }}
    </div>
    <span class="icon-dialog-spacer"></span>

    <button class="btn btn-primary sortimo_button" (click)="onUserAbort()">
      {{ 'ICON-DIALOG.ABBRECHEN' | translate }}
    </button>
    <button
      class="btn btn-primary sortimo_button"
      style="margin-left: 10px;"
      (click)="onUserClose()"
      [disabled]="forewardIsDisabled()"
    >
      {{ 'ICON-DIALOG.FORTFAHREN' | translate }}
    </button>
  </mat-toolbar-row>
</mat-toolbar>
