<div
  id="colorPicker"
  *ngIf="isVisible"
  [ngClass]="getStyleClass()"
  class="flex-col"
  [style.opacity]="opacityStyle"
  [style.pointer]="pointerStyle"
>
  <div class="headline flex-row" *ngIf="isFastTrackTextColor">
    <span
      class="headlineTitle headline_03_dk justify-center items-center flex-basis-55"
      >{{ headTitle }}</span
    >
    <span class="arrow flex-basis-5"> </span>
    <span
      class="colorLabel headline_03_dk justify-center items-center flex-basis-27"
      >{{ selectedColorName }}</span
    >
    <span (click)="close()" class="closeButton flex-basis-8">
      <mat-icon svgIcon="close-button"></mat-icon>
    </span>
  </div>

  <div class="content-container flex-row">
    <mat-icon
      *ngIf="isEditorBorder"
      class="no-border-icon"
      svgIcon="no-border"
      (click)="selectTransparentColor()"
    >
    </mat-icon>
    <div class="flex-row flex-wrap">
      <div
        class="swatch"
        *ngFor="let color of allColor"
        [ngClass]="getSelectedClass(color)"
        [style.background]="getStyleForElement(color)"
        (click)="selectColor(color.value)"
        [matTooltip]="!isFastTrackTextColor?color.name:''"
        matTooltipPosition="above"
        (mouseover)="mouseover(color.name)"
        (mouseleave)="mouseleave()"
      ></div>
      <ng-content></ng-content>  
    </div>
    <span
      *ngIf="isEditorBorder || isEditorFill || isEditorCarColor"
      (click)="close()"
      class="closeButton justify-flex-end items-center flex-1"
    >
      <mat-icon svgIcon="close-button"></mat-icon>
    </span>
  </div>
</div>
