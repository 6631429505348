<div class="fast-track flex-col">
  <span
    class="headline_03_dk sortimo_headerbar margin-bottom-xl flex-row justify-flex-start items-center"
  >
    <svg viewBox="0 0 34 34" height="34px" width="34px">
      <path
        id="anmelden_icon"
        data-name="anmelden icon"
        class="cls-1"
        d="m 25.694,8.7210687 a 8.59,8.59 0 1 1 -17.178,0 8.59,8.59 0 1 1 17.178,0 z m 8.008,19.5300003 c 0,-3.4 -3.671,-8.873 -9.044,-10.348 a 8.459,8.459 0 0 1 -15.167,-0.143 c -5.613,1.431 -9.491,7 -9.491,10.493 a 3.179,3.179 0 0 0 0.043,0.518 h 33.616 a 3.259,3.259 0 0 0 0.043,-0.52 z"
        inkscape:connector-curvature="0"
      ></path>
    </svg>
    <span style="margin-left: 20px">{{
      'FAST-TRACK.HEADLINE-1' | translate
    }}</span>
  </span>

  <div class="flex-row">
    <div class="left flex-basis-50">
      <img class="content-image" [src]="previewImagePath" />
    </div>
    <div class="right flex-basis-50">
      <form [formGroup]="dataForm" class="data-form flex-col">
        <span class="instruction flex-col">
          <h2>{{ 'FAST-TRACK.HEADLINE-2' | translate }}</h2>
          <span>{{ 'FAST-TRACK.HEADLINE-2-SUBTITLE' | translate }}</span>
        </span>

        <div
          class="form-group row flex-row justify-flex-start items-center"
        >
          <label class="flex-basis-25" for="firma"
            >{{ 'FAST-TRACK.FIRMA-NAME' | translate }}
          </label>
          <input
            id="firma"
            class="form-control"
            formControlName="firma"
            name="firma"
            maxLength="100"
            autocomplete="organization"
            [placeholder]="'FAST-TRACK.FIRMA-NAME-EXAMPLE' | translate"
          />
        </div>
        <div
          class="form-group row flex-row justify-flex-start items-center"
        >
          <label class="flex-basis-25" for="telefon"
            >{{ 'FAST-TRACK.TELEFON' | translate }}
          </label>
          <input
            id="telefon"
            class="form-control"
            formControlName="telefon"
            name="telefon"
            maxLength="100"
            autocomplete="tel"
            placeholder="{{ 'FAST-TRACK.TELEFON-EXAMPLE' | translate }}"
          />
        </div>
        <div
          class="form-group row flex-row justify-flex-start items-center"
        >
          <label class="flex-basis-25" for="url"
            >{{ 'FAST-TRACK.INTERNET' | translate }}
          </label>
          <input
            id="url"
            class="form-control"
            formControlName="url"
            name="url"
            maxLength="100"
            autocomplete="url"
            placeholder="{{ 'FAST-TRACK.INTERNET-EXAMPLE' | translate }}"
          />
        </div>

        <div
          class="form-group row flex-row justify-flex-start items-center self-stretch"
          fxLayoutWrap="wrap"
        >
          <label class="flex-basis-25" for="strasse">{{
            'FAST-TRACK.STRASSE' | translate
          }}</label>
          <input
            id="strasse"
            class="form-control flex-1"
            formControlName="strasse"
            name="strasse"
            maxLength="100"
            autocomplete="street-address"
            placeholder="{{ 'FAST-TRACK.STRASSE-EXAMPLE' | translate }}"
            style="width:220px;"
          />
          <label
            class="justify-start items-center flex-basis-10"
            style="margin-left: 20px"
            for="nr"
            >{{ 'FAST-TRACK.STRASSENUMMER' | translate }}</label
          >
          <input
            id="nr"
            style="margin-left: 5px"
            class="form-control flex-basis-20"
            formControlName="nr"
            name="nr"
            maxLength="10"
            autocomplete="street_number"
            placeholder="{{ 'FAST-TRACK.STRASSENUMMER-EXAMPLE' | translate }}"
          />
        </div>
        <div
          class="form-group row flex-row justify-flex-start items-center self-stretch"
          fxLayoutWrap="wrap"
        >
          <label class="justify-start items-center flex-basis-25" for="plz">{{
            'FAST-TRACK.PLZ' | translate
          }}</label>
          <input
            id="plz"
            class="form-control flex-basis-20"
            formControlName="plz"
            name="plz"
            maxLength="10"
            autocomplete="postal-code"
            placeholder="{{ 'FAST-TRACK.PLZ-EXAMPLE' | translate }}"
          />
          <label class="flex-basis-10" style="margin-left: 20px;" for="ort">{{
            'FAST-TRACK.ORT' | translate
          }}</label>
          <input
            id="ort"
            class="form-control flex-1"
            formControlName="ort"
            name="ort"
            maxLength="100"
            autocomplete="address-level2"
            placeholder="{{ 'FAST-TRACK.ORT-EXAMPLE' | translate }}"
            style="width:220px;"
          />
        </div>

        <div
          class="row form-group flex-row self-stretch"
          fxLayoutWrap="wrap"
        >
          <label class="flex-basis-25" for="color"></label>
          <div class="flex-row justify-flex-start items-center">
            <button
              [ngClass]="getClassForTextButton()"
              [disabled]="isColorPickerDisabled()"
              (click)="triggerTextColorChange(this)"
              matTooltip="{{ 'FAST-TRACK.TOOLTIP.COLOR' | translate }}"
              angulartics2On="click"
              angularticsEvent="CHANGE-COLOR-TEXT"
              angularticsCategory="FastTrack"
            >
              <span class="button-text">{{
                'FAST-TRACK.COLOR' | translate
              }}</span>
              <mat-icon
                class="textfarbe-icon"
                svgIcon="text-color-icon"
              ></mat-icon>
            </button>
            <div
              class="arrow-class"
              [hidden]="!textColor || textColorPicker.isVisible"
            ></div>
            <div
              class="colorelement"
              [hidden]="!textColor || textColorPicker.isVisible"
              [style.backgroundColor]="textColor"
            ></div>
            <div
              class="color-name"
              [hidden]="!textColor || textColorPicker.isVisible"
            >
              {{ getSelectedColorName() }}
            </div>

            <div class="flex-1"></div>
          </div>
        </div>
        <input
          #textcolorelement
          type="color"
          [(ngModel)]="textColor"
          [hidden]="true"
          [ngModelOptions]="{ standalone: true }"
          (change)="onChangeTextColor()"
        />
      </form>

      <app-colorpicker
        #textColorPicker
        [allColor]="getTextColorData()"
        [type]="'text-fast-track'"
        [rgbColor]="getTextRgbColor()"
        (colorChangedEvent)="changeTextColor()"
      ></app-colorpicker>
    </div>
  </div>
  <div
    class="konfigurator-button-bar flex-row justify-flex-end items-center"
  >
    <div style="display:inline;" class="justify-flex-end items-center">
      <button
        class="btn btn-outline-primary sortimo_button margin-right-xl background-white backbutton"
        (click)="previousView()"
      >
        <mat-icon class="left" svgIcon="preview-next-icon"></mat-icon>

        <span class="right"> {{ 'FAST-TRACK.BACK' | translate }} </span>
      </button>
      <button
        class="btn btn-primary sortimo_button vorschaubutton"
        [disabled]="!isNextViewEnabled()"
        (click)="nextView()"
      >
        {{ 'FAST-TRACK.FOREWARD' | translate }} >
      </button>
    </div>
  </div>
  <a
    href="3rdpartylicenses.txt"
    target="_blank"
    class="font-10px"
    style="color: #8590a2; left: 3px; top: 725px; position: absolute;"
    >Licenses</a
  >
</div>
