<div id="sortimo-image-container">
  <div class="img-container">
    <img
      [alt]="image.filename"
      [src]="image.url"
      [style.background]="background"
      [style.border-width]="borderWidth"
      [style.border-style]="'solid'"
      [style.border-color]="borderColor"
    />
  </div>
  <span class="price-label"> {{ price }}</span>
</div>
