<script
  defer
  src="../../../node_modules/fabric-customise-controls/dist/customiseControls.js"
></script>
<div class="flex-col flex-1" id="editor-container">
  <div id="overlay" *ngIf="isShowOverlay()" (click)="onOverlayClick()"></div>
  <div class="editor-content flex-col">
    <div class="editor-stage">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1280 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        style="
          fill-rule: evenodd;
          clip-rule: evenodd;
          stroke-linejoin: round;
          stroke-miterlimit: 1.41421;
        "
      >
        <path id="Rectangle.-6.-Copy" d="M84,0l1112,0l84,24l-1280,0l84,-24Z" />
      </svg>
    </div>

    <div class="svgcontainer-content flex-col">
      <div class="flex-1"></div>
      <div class="directContainer">
        <div #editoroverlay class="svgcontainer-content-overlap">
          <mat-spinner
            class="colored-spinner"
            style="margin: 120px auto"
          ></mat-spinner>
        </div>
        <div
          #editorFileUploadOverlay
          class="svgcontainer-content-overlap-for-fileupload"
        >
          <mat-spinner
            class="colored-spinner"
            style="margin: 120px auto"
          ></mat-spinner>
        </div>
        <app-car-side-handler
          id="car-side-handler"
          #carsvg
        ></app-car-side-handler>
        <canvas #canvas id="canvas"></canvas>

        <app-cropper
          #cropper
          *ngIf="isCroppingActive"
          class="custom-cropper"
          [style.left]="cropimageLeft"
          [style.top]="cropimageTop"
          [style.width]="cropimageWidth + 'px'"
          [style.height]="cropimageHeight + 'px'"
          [style.transform]="selectedImageAngle"
          format="jpeg"
          [image]="selectedImage"
          [width]="cropimageWidth"
          [height]="cropimageHeight"
          (cropperInitialized)="cropperInitialized()"
        >
          ></app-cropper
        >
      </div>
      <div
        id="topsystem-container"
        [hidden]="hideTopsystemTextearea"
        [style.display]="hideTopsystemTextearea ? 'none' : 'flex'"
      >
        <label>{{ 'EDITOR.TOPSYSTEM.HEADLINE' | translate }}</label>
        <textarea
          #topsystemTextarea
          id="topsystemtextarea"
          [style.fontFamily]="fontFamily"
          (input)="topSystemModelChange()"
          [maxLength]="maxTopSystemTextLength"
        ></textarea>
      </div>
      <div *ngIf="showDivBehindCanvas()" class="flex-1"></div>
    </div>

    <app-editor-alert-message
      type="warning"
      *ngIf="showWarning"
      (close)="warningCloseEvent()"
    >
    </app-editor-alert-message>
    <app-editor-alert-message
      type="error"
      *ngIf="showError"
      (close)="errorCloseEvent()"
    >
    </app-editor-alert-message>

    <app-colorpicker
      #textColorPicker
      [allColor]="getTextColorData()"
      [type]="'editor-text'"
      [rgbColor]="textColor"
      (colorChangedEvent)="changeTextColor($event)"
    >
    <button *ngIf="!isCustomColor" (click)="isCustomColor = true">customColor</button>
    <input
      class="car-color-input"
      *ngIf="isCustomColor"
      [(ngModel)]="carColorCode"
      type="text"
      (keyup.enter)="onCustomTextColorChange()"
    />
    </app-colorpicker>

    <app-colorpicker
    #carColorPicker
    [allColor]="getCarColorData()"
    [type]="'editor-car'"
    [rgbColor]="carColor"
    (colorChangedEvent)="changeCarColor($event)"
  >
  </app-colorpicker>

    <app-colorpicker
      #borderColorPicker
      [allColor]="getTextColorData()"
      [type]="'editor-border'"
      [rgbColor]="activeBorderColor"
      (colorChangedEvent)="changeBorderColor($event)"
    ></app-colorpicker>

    <div class="direct-control-bar flex-row" style="justify-content: end;">
      <div
        class="direct-controls flex-row"
        style=" display: flex; justify-content: flex-start; align-items: center; align-content: center"
        >
        <div class="default-control-title">
          <p>{{ 'EDITOR.TOOLBAR.OBJECTPROPERTIES' | translate }}</p>
        </div>
        <div class="default-control-arrow-container">
          <div class="default-control-arrow"></div>
        </div>
        <div
          class="default-controls flex-row"
          fxLayoutAlignment="start center"
        >
          <app-shape-color-selector-button
            #shapeColorSelector
            [activeObject]="activeObject"
            (fillClicked)="triggerTextColorChange()"
            (strokeClicked)="triggerStrokeColorChange()"
            (strokeWidthUpdated)="triggerStrokeWidthChange($event)"
          ></app-shape-color-selector-button>

          <input
            type="color"
            [(ngModel)]="textColor"
            [hidden]="true"
            (change)="onChangeTextColor()"
          />
          <div class="vertical-line"></div>
          <button
            class="default-control-icon"
            [disabled]="
              (!showObjectControls() && !isActiveGroup()) || isTopSystemActive||isInZoomMode
            "
            (click)="setShowCopyMenu()"
            matTooltip="{{ 'EDITOR.TOOLTIP.COPY-ICON-TEXT' | translate }}"
            [matTooltipDisabled]="isShowCopyMenu"
            angulartics2On="click"
            angularticsEvent="COPY-BUTTON"
            angularticsCategory="EditorFunction"
          >
            <mat-icon svgIcon="copy-icon" class="copy-icon"></mat-icon>
            <div id="editor-copy-menu" *ngIf="isShowCopyMenu">
              <button
                (click)="copySelectionToFahrzeugseite('BEIFAHRER')"
                matTooltip="{{
                  'EDITOR.TOOLTIP.COPY-TO-BEIFAHRER' | translate
                }}"
                angulartics2On="click"
                angularticsEvent="COPY-TO-BEIFAHRER"
                angularticsCategory="EditorFunction"
              >
                <mat-icon svgIcon="image-car-beifahrer"></mat-icon>
              </button>
              <button
                (click)="copySelectionToFahrzeugseite('FRONT')"
                matTooltip="{{ 'EDITOR.TOOLTIP.COPY-TO-FRONT' | translate }}"
                angulartics2On="click"
                angularticsEvent="COPY-TO-FRONT"
                angularticsCategory="EditorFunction"
              >
                <mat-icon svgIcon="image-car-front"></mat-icon>
              </button>
              <button
                (click)="copySelectionToFahrzeugseite('HECK')"
                matTooltip="{{ 'EDITOR.TOOLTIP.COPY-TO-HECK' | translate }}"
                angulartics2On="click"
                angularticsEvent="COPY-TO-HECK"
                angularticsCategory="EditorFunction"
              >
                <mat-icon svgIcon="image-car-heck"></mat-icon>
              </button>
              <button
                (click)="copySelectionToFahrzeugseite('FAHRER')"
                matTooltip="{{ 'EDITOR.TOOLTIP.COPY-TO-FAHRER' | translate }}"
                angulartics2On="click"
                angularticsEvent="COPY-TO-FAHRER"
                angularticsCategory="EditorFunction"
              >
                <mat-icon svgIcon="image-car-fahrer"></mat-icon>
              </button>
            </div>
          </button>
        </div>

        <div *ngIf="isTextSelected" class="text-controls flex-row">
          <div class="vertical-line"></div>
          <mat-select
            #fontStyle
            class="fontStyle"
            panelClass="editor-font-style-panel"
            [disabled]="!showTextControls()"
            placeholder="Schriftart"
            [(ngModel)]="fontFamily"
            (selectionChange)="onChangeFontFamily()"
            floatPlaceholder="never"
            dropdown-menu-right
            angulartics2On="selectionChanged"
            angularticsEvent="CHANGE-FONT"
            angularticsCategory="EditorFunction"
          >
            <mat-option
              *ngFor="let font of allFont"
              [value]="font"
              [style.fontFamily]="font"
              >{{ font }}
            </mat-option>
          </mat-select>
          <input
            #fontSizeStyle
            id="fontSizeStyle"
            class="fontSizeStyle"
            [disabled]="!showTextControls() || isTopSystemActive"
            type="number"
            min="4"
            max="120"
            step="1"
            [(ngModel)]="fontSize"
            (change)="onChangeFontSize($event)"
            angulartics2On="change"
            angularticsEvent="CHANGE-FONTSIZE"
            angularticsCategory="EditorFunction"
            ng-reflect-model="8"
            ng-reflect-angulartics2-on="change"
            ng-reflect-angulartics-category="EditorFunction"
          />
          <button
            [ngClass]="'iconbuttonsmall'"
            (click)="changeTextBold()"
            [disabled]="!showTextControls() || !isBoldEnabled()"
            matTooltip="{{ 'EDITOR.TOOLTIP.TEXT-BOLD' | translate }}"
            angulartics2On="click"
            angularticsEvent="TEXT-BOLD"
            angularticsCategory="EditorFunction"
          >
            <mat-icon
              [ngClass]="getClassForButton('Bold')"
              svgIcon="icon-bold"
            ></mat-icon>
          </button>
          <button
            [ngClass]="'iconbuttonsmall'"
            (click)="changeTextItalic()"
            [disabled]="!showTextControls() || !isItalicEnabled()"
            matTooltip="{{ 'EDITOR.TOOLTIP.TEXT-ITALIC' | translate }}"
            angulartics2On="click"
            angularticsEvent="TEXT-ITALIC"
            angularticsCategory="EditorFunction"
          >
            <mat-icon
              [ngClass]="getClassForButton('Italic')"
              svgIcon="icon-italic"
            ></mat-icon>
          </button>
          <button
            [ngClass]="'iconbuttonsmall'"
            (click)="changeTextUnderline()"
            [disabled]="!showTextControls()"
            matTooltip="{{ 'EDITOR.TOOLTIP.TEXT-UNDERLINE' | translate }}"
            angulartics2On="click"
            angularticsEvent="TEXT-UNDERLINE"
            angularticsCategory="EditorFunction"
          >
            <mat-icon
              [ngClass]="getClassForButton('Underline')"
              svgIcon="icon-underline"
            ></mat-icon>
          </button>

          <button
            [ngClass]="'iconbuttonsmall'"
            (click)="setShowTextAlignMenu()"
            matTooltip="{{
              'EDITOR.TOOLTIP.TEXT-ALIGNMENT-SELECTION' | translate
            }}"
            [matTooltipDisabled]="isShowTextAlignMenu"
            [disabled]="isTopSystemActive"
            angulartics2On="click"
            angularticsEvent="TEXT--ALIGNMENT-SELECTION"
            angularticsCategory="EditorFunction"
          >
            <mat-icon svgIcon="icon-alignLeft"></mat-icon>

            <div id="editor-text-align-menu" *ngIf="isShowTextAlignMenu">
              <button
                [disabled]="
                  isEnabledTextAlignButton('left') || isTopSystemActive
                "
                (click)="clickTextAlign('left')"
                matTooltip="{{ 'EDITOR.TOOLTIP.TEXT-ALIGNLEFT' | translate }}"
                angulartics2On="click"
                angularticsEvent="TEXT-ALIGNLEFT"
                angularticsCategory="EditorFunction"
              >
                <mat-icon svgIcon="icon-alignLeft"></mat-icon>
              </button>
              <button
                [disabled]="
                  isEnabledTextAlignButton('center') || isTopSystemActive
                "
                (click)="clickTextAlign('center')"
                matTooltip="{{ 'EDITOR.TOOLTIP.TEXT-ALIGNCENTER' | translate }}"
                angulartics2On="click"
                angularticsEvent="TEXT-ALIGNCENTER"
                angularticsCategory="EditorFunction"
              >
                <mat-icon svgIcon="icon-alignCenter"></mat-icon>
              </button>
              <button
                [disabled]="
                  isEnabledTextAlignButton('right') || isTopSystemActive
                "
                (click)="clickTextAlign('right')"
                matTooltip="{{ 'EDITOR.TOOLTIP.TEXT-ALIGNRIGHT' | translate }}"
                angulartics2On="click"
                angularticsEvent="TEXT-ALIGNRIGHT"
                angularticsCategory="EditorFunction"
              >
                <mat-icon svgIcon="icon-alignRight"></mat-icon>
              </button>
            </div>
          </button>
          <div class="spacing-container">
            <button
              (mouseover)="hideSpacing()"
              [hidden]="isSpacingIconHidden"
              [ngClass]="'iconbuttonsmall'"
              angulartics2On="hover"
              angularticsEvent="CHANGE-TEXT-SPACING"
              angularticsCategory="EditorFunction"
            >
              <mat-icon svgIcon="icon-spacing"></mat-icon>
            </button>
            <input
              #letterSpacingSelector
              (mouseleave)="showSpacing()"
              [hidden]="!isSpacingIconHidden"
              [ngClass]="'iconbuttonsmallletterspacing'"
              [disabled]="!showTextControls() || isTopSystemActive"
              type="number"
              min="-500"
              max="10000"
              step="25"
              [(ngModel)]="letterSpacing"
              (change)="onChangeLetterSpacing()"
            />
          </div>
          <div class="vertical-line"></div>
          <app-element-measurement
            #measureComponentText
            [activeObject]="activeObject"
            [isTopSystemActive]="isTopSystemActive"
            [rotationOnly]="true"
            (rotationUpdated)="triggerElementRotationUpdate($event)"
          >
          </app-element-measurement>
        </div>
        <div *ngIf="isImageSelected" class="image-controls flex-row">
          <div class="vertical-line"></div>
          <button
            [disabled]="!isJpegImageSelected()"
            type="mirror"
            (click)="mirrorObject()"
            [ngClass]="'iconbuttonsmall'"
            matTooltip="{{ 'EDITOR.TOOLTIP.MIRROR-IMAGE' | translate }}"
            angulartics2On="click"
            angularticsEvent="MIRROR-IMAGE"
            angularticsCategory="EditorFunction"
          >
            <mat-icon svgIcon="mirror-image"></mat-icon>
          </button>
          <button
            [disabled]="!isJpegImageSelected() || isInZoomMode"
            [ngClass]="cropActiveClass"
            matTooltip="{{ 'EDITOR.TOOLTIP.CROP-IMAGE' | translate }}"
            angulartics2On="click"
            angularticsEvent="CROP-IMAGE"
            angularticsCategory="EditorFunction"
            (click)="clickCropImage()"
          >
            <mat-icon svgIcon="crop-image"></mat-icon>
          </button>
          <div class="vertical-line"></div>
          <app-element-measurement
            #measureComponentImage
            [activeObject]="activeObject"
            [isTopSystemActive]="isTopSystemActive"
            (widthUpdated)="triggerElementWidthUpdate($event)"
            (heightUpdated)="triggerElementHeightUpdate($event)"
            (rotationUpdated)="triggerElementRotationUpdate($event)"
          >
          </app-element-measurement>
        </div>
        <div *ngIf="isShapeSelected" class="shape-controls flex-row">
          <div class="vertical-line"></div>
          <app-element-measurement
            #measureComponentShape
            [activeObject]="activeObject"
            [isTopSystemActive]="isTopSystemActive"
            (widthUpdated)="triggerElementWidthUpdate($event)"
            (heightUpdated)="triggerElementHeightUpdate($event)"
            (rotationUpdated)="triggerElementRotationUpdate($event)"
          >
          </app-element-measurement>
        </div>
        <div
          *ngIf="isActiveGroup()"
          class="group-controls flex-row"
        ></div>
      </div>
      <div
        class="delete-button flex-row"
        fxLayoutAlignment="center center"
      >
        <button
          class="iconbutton flex-row"
          fxLayoutAlignment="center center"
          [disabled]="isDeleteDisabled"
          (click)="removeObject()"
          matTooltip="{{ 'EDITOR.TOOLTIP.DELETE-ELEMENT' | translate }}"
          angulartics2On="click"
          angularticsEvent="DELETE-ELEMENT"
          angularticsCategory="EditorFunction"
        >
          <mat-icon svgIcon="trash-icon"></mat-icon>
        </button>
      </div>
    </div>

    <div class="general-control-panel flex-row">
      <button
        [ngClass]="'iconbutton'"
        (click)="addTextElement()"
        [disabled]="isTopSystemActive"
        matTooltip="{{ 'EDITOR.TOOLTIP.ADD-TEXT' | translate }}"
        matTooltipPosition="above"
        angulartics2On="click"
        angularticsEvent="ADD-TEXT"
        angularticsCategory="EditorFunction"
      >
        <mat-icon svgIcon="text-box"></mat-icon>
      </button>
      <button
        [ngClass]="'iconbutton'"
        matTooltip="{{ 'EDITOR.TOOLTIP.ADD-SHAPE' | translate }}"
        [matTooltipDisabled]="isShowFormAddMenu"
        matTooltipPosition="above"
        (click)="setShowFormAddMenu()"
      >
        <mat-icon svgIcon="shapes-icon"></mat-icon>
        <div id="editor-form-menu" *ngIf="isShowFormAddMenu">
          <button
            (click)="addRectangle()"
            matTooltip="{{ 'EDITOR.TOOLTIP.ADD-RECT' | translate }}"
            angulartics2On="click"
            angularticsEvent="ADD-RECTANGLE"
            angularticsCategory="EditorFunction"
          >
            <mat-icon
              class="editor-form-icon"
              svgIcon="shape-rect-icon"
            ></mat-icon>
          </button>
          <button
            (click)="addTriangle()"
            matTooltip="{{ 'EDITOR.TOOLTIP.ADD-TRIANGLE' | translate }}"
            angulartics2On="click"
            angularticsEvent="ADD-TRIANGLE"
            angularticsCategory="EditorFunction"
          >
            <mat-icon
              class="editor-form-icon"
              svgIcon="shape-triangle-icon"
            ></mat-icon>
          </button>
          <button
            (click)="addCircle()"
            matTooltip="{{ 'EDITOR.TOOLTIP.ADD-CIRCLE' | translate }}"
            angulartics2On="click"
            angularticsEvent="ADD-CIRCLE"
            angularticsCategory="EditorFunction"
          >
            <mat-icon
              class="editor-form-icon"
              svgIcon="shape-circle-icon"
            ></mat-icon>
          </button>
        </div>
      </button>
      <div
      class="default-controls flex-row"
      fxLayoutAlignment="start center">
        <app-car-color-change
        #carColorSelector
        [activeObject]="activeCarColor"
        (fillClicked)="triggerCarColorChange()">
        </app-car-color-change>
        <input
        type="color"
        [(ngModel)]="carColor"
        [hidden]="true"
        (change)="onChangeCarColor()"
        />
      </div>
      <button
        [ngClass]="'iconbutton'"
        (click)="addLogoElement()"
        matTooltip="{{ 'EDITOR.TOOLTIP.ADD-IMAGE' | translate }}"
        matTooltipPosition="above"
        angulartics2On="click"
        angularticsEvent="ADD-IMAGE"
        angularticsCategory="EditorFunction"
      >
        <mat-icon svgIcon="image-box"></mat-icon>
      </button>
      <div class="vertical-line"></div>

      <button
        [ngClass]="'iconbutton'"
        [disabled]="!showObjectControls()"
        (click)="sendObjectForeward()"
        matTooltip="{{ 'EDITOR.TOOLTIP.MOVE-FRONT' | translate }}"
        matTooltipPosition="above"
        angulartics2On="click"
        angularticsEvent="MOVE-FRONT"
        angularticsCategory="EditorFunction"
      >
        <mat-icon svgIcon="move-front"></mat-icon>
      </button>
      <button
        [ngClass]="'iconbutton'"
        [disabled]="!showObjectControls()"
        (click)="sendObjectBackward()"
        matTooltip="{{ 'EDITOR.TOOLTIP.MOVE-BACK' | translate }}"
        matTooltipPosition="above"
        angulartics2On="click"
        angularticsEvent="MOVE-BACK"
        angularticsCategory="EditorFunction"
      >
        <mat-icon svgIcon="move-back"></mat-icon>
      </button>
      <button
        (click)="setShowAlignMenu()"
        [ngClass]="'iconbutton'"
        [disabled]="!isAlignmentEnabled()"
        matTooltip="{{ 'EDITOR.TOOLTIP.ORDER-OBJECTS' | translate }}"
        [matTooltipDisabled]="isShowAlignMenu"
        matTooltipPosition="above"
        angulartics2On="click"
        angularticsEvent="ALIGN"
        angularticsCategory="EditorControls"
      >
        <mat-icon svgIcon="object-align"></mat-icon>
        <div
          id="editor-align-menu"
          [ngClass]="getAlignElementMenuClass()"
          *ngIf="isShowAlignMenu"
        >
          <button
            *ngIf="!isSingleObject()"
            (click)="alignLeft()"
            matTooltip="{{ 'EDITOR.TOOLTIP.ALIGN-LEFT' | translate }}"
            angulartics2On="click"
            angularticsEvent="ALIGN-LEFT"
            angularticsCategory="EditorFunction"
          >
            <mat-icon
              style="opacity: 1; color: black; margin-right: 0px; width: 36px"
              svgIcon="object-align-left"
            ></mat-icon>
          </button>
          <button
            *ngIf="!isSingleObject()"
            (click)="alignRight()"
            matTooltip="{{ 'EDITOR.TOOLTIP.ALIGN-RIGHT' | translate }}"
            angulartics2On="click"
            angularticsEvent="ALIGN-RIGHT"
            angularticsCategory="EditorFunction"
          >
            <mat-icon svgIcon="object-align-right"></mat-icon>
          </button>
          <button
            *ngIf="!isSingleObject()"
            (click)="alignTop()"
            matTooltip="{{ 'EDITOR.TOOLTIP.ALIGN-TOP' | translate }}"
            angulartics2On="click"
            angularticsEvent="ALIGN-TOP"
            angularticsCategory="EditorFunction"
          >
            <mat-icon svgIcon="object-align-top"></mat-icon>
          </button>
          <button
            *ngIf="!isSingleObject()"
            (click)="alignBottom()"
            matTooltip="{{ 'EDITOR.TOOLTIP.ALIGN-BOTTOM' | translate }}"
            angulartics2On="click"
            angularticsEvent="ALIGN-BOTTOM"
            angularticsCategory="EditorFunction"
          >
            <mat-icon svgIcon="object-align-bottom"></mat-icon>
          </button>
          <button
            (click)="alignHorizontalCenter()"
            matTooltip="{{
              'EDITOR.TOOLTIP.ALIGN-HORIZONTALCENTER' | translate
            }}"
            angulartics2On="click"
            angularticsEvent="ALIGN-HORIZONTALCENTER"
            angularticsCategory="EditorFunction"
          >
            <mat-icon svgIcon="object-align-vertical"></mat-icon>
          </button>
          <button
            *ngIf="!isSingleObject()"
            (click)="alignVerticalCenter()"
            matTooltip="{{ 'EDITOR.TOOLTIP.ALIGN-VERTICALCENTER' | translate }}"
            angulartics2On="click"
            angularticsEvent="ALIGN-VERTICALCENTER"
            angularticsCategory="EditorFunction"
          >
            <mat-icon svgIcon="object-align-horizontal"></mat-icon>
          </button>

          <button
            *ngIf="!isSingleObject()"
            (click)="alignHorizontalSpread()"
            matTooltip="{{
              'EDITOR.TOOLTIP.ALIGN-HORIZONTALSPREAD' | translate
            }}"
            angulartics2On="click"
            angularticsEvent="ALIGN-HORIZONTALSPREAD"
            angularticsCategory="EditorFunction"
          >
            <mat-icon svgIcon="object-dist-horizontal"></mat-icon>
          </button>
          <button
            *ngIf="!isSingleObject()"
            (click)="alignVerticalSpread()"
            matTooltip="{{ 'EDITOR.TOOLTIP.ALIGN-VERTICALSPREAD' | translate }}"
            angulartics2On="click"
            angularticsEvent="ALIGN-VERTICALSPREAD"
            angularticsCategory="EditorFunction"
          >
            <mat-icon svgIcon="object-dist-vertical"></mat-icon>
          </button>
        </div>
      </button>

      <div class="vertical-line"></div>
      <button
        [ngClass]="'iconbutton'"
        (click)="stateStorageUndo()"
        [disabled]="isUndoDisabled()"
        matTooltip="{{ 'EDITOR.TOOLTIP.UNDO-CLICK' | translate }}"
        matTooltipPosition="above"
        angulartics2On="click"
        angularticsEvent="UNDO-CLICK"
        angularticsCategory="StateManagement"
      >
        <mat-icon svgIcon="undo-icon"></mat-icon>
      </button>
      <button
        [ngClass]="'iconbutton'"
        (click)="stateStorageRedo()"
        [disabled]="isRedoDisabled()"
        matTooltip="{{ 'EDITOR.TOOLTIP.REDO-CLICK' | translate }}"
        matTooltipPosition="above"
        angulartics2On="click"
        angularticsEvent="REDO-CLICK"
        angularticsCategory="StateManagement"
      >
        <mat-icon svgIcon="redo-icon"></mat-icon>
      </button>

      <div class="vertical-line"></div>
      <div
        class="zoom-container flex-row"
        fxLayoutAlignment="start center"
      >
        <button
          [disabled]="!this.disableZoom || isCroppingActive"
          [ngClass]="'iconbutton'"
          (click)="zoomOut()"
          matTooltip="{{ 'EDITOR.TOOLTIP.ZOOM-OUT' | translate }}"
          matTooltipPosition="above"
        >
          <mat-icon svgIcon="zoom-out"></mat-icon>
        </button>
        <div
          class="percentage-container flex-row"
          fxLayoutAlignment="start center"
        >
          <input class="zoom-input" disabled readonly [ngModel]="zoomLevel" />
          <p>{{ 'EDITOR.ZOOM.PERCENT' | translate }}</p>
        </div>
        <button
          [disabled]="isInZoomMode || isCroppingActive"
          [ngClass]="'iconbutton'"
          (click)="zoomIn()"
          matTooltip="{{ 'EDITOR.TOOLTIP.ZOOM-IN' | translate }}"
          matTooltipPosition="above"
        >
          <mat-icon svgIcon="zoom-in"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
