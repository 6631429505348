<div #container class="toggle-switch">
  <input
    type="checkbox"
    [id]="inputId"
    [checked]="isChecked"
    [disabled]="isDisabled"
  />
  <label
    #labelElement
    [for]="inputId"
    [class.image-upload]="isImageUploadParent"
    [class.toggle-switch__disabled]="isDisabled"
  >
    <ng-content></ng-content>
  </label>
</div>
