<div class="margin-right-xl price-display flex-row">
  <div class="left-container justify-center items-center">
    <ul *ngIf="!isDesignUser()">
      <li class="left-container-list">
        {{ 'PREIS.EINFUEHRUNGSPREIS' | translate }}
      </li>
      <li *ngIf="!isProUser()" class="left-container-sub left-container-list">
        {{ 'PREIS.BEKLEBUNG' | translate }}
      </li>
      <li *ngIf="isProUser()" class="left-container-sub left-container-list">
        <app-toggle-switch
          [inputId]="'material-only-toggle'"
          [labelWidth]="'80px'"
          [labelHeight]="'27px'"
          [isChecked]="!materialOnly"
          [isDisabled]="false"
          (click)="toggleMaterial($event)"
        >
          <span *ngIf="!materialOnly">
            {{ 'PREIS.BEKLEBUNG' | translate }}</span
          >
          <span *ngIf="materialOnly">
            {{ 'PREIS.WITHOUT_GLUEING' | translate }}</span
          >
        </app-toggle-switch>
      </li>
    </ul>
    <ul *ngIf="isDesignUser()">
      <li class="left-container-list" style="font-size: 14px;">
        {{ 'PREIS.DESIGN_HQ_PRICE' | translate }}
      </li>
      <li class="left-container-sub left-container-list" style="font-size: 12px;">
        {{ 'PREIS.DESIGN_MINUTES' | translate }}
      </li>
      <li class="left-container-sub left-container-list" style="font-size: 12px;">
        {{ 'PREIS.DESIGN_VK_PRICE' | translate }}
      </li>

    </ul>
  </div>
  <div class="rect-container"><div class="rect"></div></div>
  <div class="right-container justify-center items-center">
    <div class="right-inner-container">
      <span
      *ngIf="isDesignUser() && !allowEdit"
      class="price-label-design font-bold flex-col"
      ><span>{{ predefinedHqPrice }}</span>
      <span>{{ predefinedMinutes }}</span>
      <span>{{ predefinedVkPrice }}</span>
      </span >
      <span
        *ngIf="isDesignUser() && allowEdit"
        class="price-label font-bold design-user-input flex-col"
      >
        <span>
          <input type="number" step="any" [(ngModel)]="predefinedHqPrice" (keyup)="onUpdateHqPrice()"/>
          {{ '0' | currency: getCurrency():'symbol' | slice: 0:1 }}
        </span>
        <span>
          <input type="number" step="any" [(ngModel)]="predefinedMinutes" />{{'PREIS.DESIGN.MIN' |translate}}
        </span>
          <span> <input type="number" step="any" [(ngModel)]="predefinedVkPrice" />
          {{ '0' | currency: getCurrency():'symbol' | slice: 0:1 }}
        </span>

      </span>

      <span
        *ngIf="!isDesignUser()"
        class="price-label font-bold flex-col"
        >{{ hasCalculatedPrice() ? getPrice() : '?' }}</span
      >
      <span *ngIf="!isDesignUser()" class="small-text">{{ getPriceString() }}</span>
    </div>
  </div>
</div>
