<image-cropper
  [imageBase64]="imageSource"
  [maintainAspectRatio]="false"
  [aspectRatio]="1 / 1"
  autoCrop="true"
  format="png"
  (imageCropped)="imageCropped($event)"
  (imageLoaded)="imageLoaded()"
  (cropperReady)="cropperReady()"
  (loadImageFailed)="loadImageFailed()"
></image-cropper>
