/**
 * Enviornment variable for Production System
 */
export const environment = {
  production: true,
  test: false,
  local: false,
  STAGE: 'production',
  graphicsId: '',
  backend_protocol: 'https://',
  backend_prefix: 'graphics-server',
  client_prefix: 'graphics-client',
  backend_url: 'http://localhost:3000/',
  start_path: 'startParameter',
  finish_path: 'order/finish',
  convert_path: 'upload',
  long_poll_convert_path: 'upload/convert-image-lp',
  long_poll_finish_path: 'order/finish-lp',
  convert_sortimo_image_path: 'upload/sortimo-image',
  mirror_path: 'mirror',
  all_image_path: 'images',
  logLevel: 'warn',
  assets_url: 'https://gfxassets.mysortimo.de/'
};
