<img src="../../assets/content/step-{{ step }}.svg" />
<div class="preview-stepper-texts flex-row">
  <div
    class="justify-center flex-1"
    [ngClass]="{ 'active-step': getStep() === 1 }"
  >
    {{ 'PREVIEW.STEP-1' | translate }}
  </div>
  <div class="flex-1"></div>
  <div
    class="justify-center flex-1"
    [ngClass]="{ 'active-step': getStep() === 2 }"
  >
    {{ 'PREVIEW.STEP-2' | translate }}
  </div>
  <div class="flex-1"></div>
  <div
    class="justify-center flex-1"
    [ngClass]="{ 'active-step': getStep() === 3 }"
  >
    {{ 'PREVIEW.STEP-3' | translate }}
  </div>
  <div class="flex-1"></div>
  <div
    class="justify-center flex-1"
    [ngClass]="{ 'active-step': getStep() === 4 }"
  >
    {{ 'PREVIEW.STEP-4' | translate }}
  </div>
</div>
